import { gql } from '@apollo/client';

export enum TwoFactorConfirmResources {
  EMAIL = 'EMAIL',
}

export enum TwoFactorConfirmActions {
  SINGIN = 'SINGIN',
}

export type MyClientModel = {
  id: string;
  username: string;
  birthday: string | null;
  avatar: string;
  email: string;
  language: 'en' | 'ru';
  googleAccountConnected: boolean;
  twoFactorConfirm: {
    resource: TwoFactorConfirmResources;
    action: TwoFactorConfirmActions;
  }[];
  balanceVusd: number;
  snapshotVts: number;
  vusdLiveBids: number;
  vusdLiveComission: number;
  vusdLiveTokens: number;
  vusdLiveRevenue: number;
  createdAt: string;
  moderator: boolean;
  editor: boolean;
  isBetsActive: boolean;
};

export interface GetMyClientQuery {
  getMyClient: {
    client: MyClientModel | null;
    abilities: { action: string; resource: string }[];
  };
}

export interface GetMyClientQueryVars {
  token: string | null;
}

const GET_MY_CLIENT = gql`
  query GetMyClient($token: String) {
    getMyClient(token: $token) {
      client {
        id
        username
        birthday
        avatar
        email
        language
        googleAccountConnected
        twoFactorConfirm {
          resource
          action
        }
        isBetsActive

        balanceVusd
        snapshotVts
        vusdLiveBids
        vusdLiveComission
        vusdLiveTokens
        vusdLiveRevenue
        createdAt
      }
      abilities {
        action
        resource
      }
    }
  }
`;

export default GET_MY_CLIENT;
