import ArrowDownIcon from './arrow-down-m';
import ArrowDownXsIcon from './arrow-down-xs';
import { ArrowLeftIcon } from './arrow-left';
import ArrowRightIcon from './arrow-right';
import ArrowRightMIcon from './arrow-right-m';
import ArrowUpXsIcon from './arrow-up-xs';
import {
  BandagedHandIcon,
  BellIcon,
  GlovesTouchingIcon,
  PunchBagIcon,
  RobeIcon,
  RoundIcon,
  TicketIcon,
  TitleBeltIcon,
  WorldTitleIcon,
} from './challenge';
import ChangeIcon from './change';
import CheckboxCheckedIcon from './checkbox-checked';
import CheckboxUncheckedIcon from './checkbox-unchecked';
import CloseIcon from './close';
import CreateNewsIcon from './create-news.svg';
import DownIcon from './down';
import Edit from './edit.svg';
import EyeIcon from './eye';
import HistoryIcon from './history';
import InstagramIcon from './instagram';
import Like from './like.svg';
import PersonIcon from './person';
import SearchIcon from './search';
import SortIcon from './sort';
import SortAscIcon from './sort-asc';
import SortDescIcon from './sort-desc';
import StatisticIcon from './statistic';
import TelegramIcon from './telegram';
import Trash from './trash.svg';
import Union from './union';
import UpIcon from './up';
import UserIcon from './user.svg';
import WalletIcon from './wallet';
import YoutubeIcon from './youtube';

export {
  ArrowLeftIcon,
  ChangeIcon,
  BandagedHandIcon,
  WorldTitleIcon,
  TitleBeltIcon,
  PunchBagIcon,
  GlovesTouchingIcon,
  RobeIcon,
  RoundIcon,
  TicketIcon,
  BellIcon,
  ArrowDownXsIcon,
  ArrowUpXsIcon,
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowRightMIcon,
  DownIcon,
  EyeIcon,
  InstagramIcon,
  TelegramIcon,
  UpIcon,
  UserIcon,
  YoutubeIcon,
  HistoryIcon,
  SearchIcon,
  StatisticIcon,
  WalletIcon,
  CloseIcon,
  SortIcon,
  SortAscIcon,
  SortDescIcon,
  PersonIcon,
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon,
  CreateNewsIcon,
  Trash,
  Like,
  Edit,
  Union,
};
