const i18n = {
  locales: ['en', 'ru'],
  defaultLocale: 'en',
  localeDetection: true,
};

/** @type {import('next-i18next').UserConfig} */
const config = {
  i18n,
  serializeConfig: false,
};

export default config;
