import React from 'react';

import { Button } from 'ui';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import { Edit, Trash, Union } from 'ui/icons';

import css from './remove-edit-buttons.module.scss';
import useWindowDimensions from '../../application/hooks/use-window-dimensions';

interface RemoveEditButtonsProps {
  className?: string;
  remove: () => void;
  edit: () => void;
  merge?: () => void;
}

const RemoveEditButtons: React.FC<RemoveEditButtonsProps> = ({
  className,
  remove,
  edit,
  merge,
}) => {
  const { width } = useWindowDimensions();

  return (
    <Flex
      gap={width > 600 ? GridGap.x1 : GridGap.x0}
      alignItems="center"
      className={className || ''}
    >
      <Button
        icon={<Union />}
        color="empty"
        className={css.Button}
        onClick={merge}
      />
      <Button
        icon={<Trash />}
        color="empty"
        className={css.Button}
        onClick={remove}
      />
      <Button
        icon={<Edit />}
        color="empty"
        className={css.Button}
        onClick={edit}
      />
    </Flex>
  );
};

export default RemoveEditButtons;
