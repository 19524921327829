import React, { CSSProperties } from 'react';

import { GridGap } from 'ui/grid/grid.types';

export interface FlexProps {
  children: React.ReactNode;
  gap?: GridGap | string;
  alignItems?: string;
  flexDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  justifyContent?: string;
  className?: string;
  marginBottom?: string;
  marginTop?: string;
  width?: string;
  wrap?: string;
  hide?: boolean;
  display?: string;
  style?: CSSProperties;
}

export const Flex: React.FC<FlexProps> = ({
  children,
  gap,
  className,
  justifyContent,
  marginBottom,
  marginTop,
  alignItems,
  flexDirection = 'row',
  width = 'auto',
  wrap = 'initial',
  hide,
  style,
  display = 'flex',
  ...args
}) => {
  const styles = {
    display,
    ...(gap ? { gap } : null),
    ...(alignItems ? { alignItems } : null),
    ...(flexDirection ? { flexDirection } : null),
    ...(justifyContent ? { justifyContent } : null),
    ...(marginBottom ? { marginBottom } : null),
    ...(marginTop ? { marginTop } : null),
    ...(width ? { width } : null),
    ...(wrap ? { flexWrap: wrap } : null),
    ...style,
  } as CSSProperties;

  return hide ? null : (
    <div style={styles} className={className}>
      {children}
    </div>
  );
};
