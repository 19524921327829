import { ToastContainer, toast } from 'react-toastify';

import config from 'application/config';
import webApp from 'application/web-app';
import ErrorIcon from 'ui/icons/error';
import InfoIcon from 'ui/icons/info';
import SuccessIcon from 'ui/icons/success';
import WarningIcon from 'ui/icons/warning';

import Toast from './toast';

const info = (title: string, body?: string) =>
  toast.info(<Toast title={title} body={body} />, { icon: <InfoIcon /> });

const warning = (title: string, body?: string) =>
  toast.warning(<Toast title={title} body={body} />, { icon: <WarningIcon /> });

const error = (title: string, body?: string) =>
  toast.error(<Toast title={title} body={body} />, { icon: <ErrorIcon /> });

const debugError = (title: string, body?: string) => {
  if (config.env === 'prod') {
    webApp.log.exception({ title, body });
  } else {
    toast.error(<Toast title={title} body={body} />, { icon: <ErrorIcon /> });
  }
};

const success = (title: string, body?: string) =>
  toast.success(<Toast title={title} body={body} />, { icon: <SuccessIcon /> });

const AppToastContainer: React.FC = () => (
  <ToastContainer
    position="bottom-right"
    autoClose={5000}
    hideProgressBar={true}
    newestOnTop={false}
    draggable={false}
    closeOnClick
    pauseOnHover
  />
);

export { success, error, debugError, info, warning, AppToastContainer };
