import clsx from 'clsx';
import React from 'react';

import css from './card-content.module.scss';

interface CardContent {
  children: React.ReactNode;
  noVertical?: boolean;
  className?: string;
}

const CardContent: React.FC<CardContent> = ({
  children,
  noVertical,
  className,
}) => {
  return (
    <div
      className={clsx(className, noVertical ? css.WithoutVertical : css.Root)}
    >
      {children}
    </div>
  );
};

export default CardContent;
